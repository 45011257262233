import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

//constants
import { textValues } from '@/modules/scholarshipPage/scholarshipDataList/partials/filterSort/constants';

//materials
import { CheckCircle, FilterAlt } from '@mui/icons-material';
import { Box, Button, Drawer, Modal } from '@mui/material';
import IconButton from '@mui/material/IconButton';

//components
import JsonForm from '@/components/widgets/form';

//style
import useStyles from './style';

let timeout = null;
export default function FilterSort({
	handleModalClose,
	handleModalOpen,
	...props
}) {
	const { classes, cx } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);

	const handleChange = function ({ data }) {
		if (timeout) clearTimeout(timeout);
		timeout = setTimeout(() => {
			props.setFilters(data);
		}, 400);
	};

	const renderModal = () => {
		if (isMobile) {
			return (
				<Drawer
					anchor="right"
					open={props.modal}
					onClose={handleModalClose}
					PaperProps={{ style: { width: '100%', padding: '10px' } }}
				>
					<Box className={classes.drawer}>
						<JsonForm
							uiSchema={props.filterUiSchema}
							schema={props.filterSchema}
							data={props.filters}
							onChange={(e) => {
								props.setFilters(e.data);
							}}
						/>
						<Box className={classes.drawerActions}>
							<Button
								onClick={props.applyFilter}
								variant="contained"
								color={'success'}
								fullWidth
								className={classes.drawerApply}
							>
								{textValues.applyFilter}
							</Button>
							<Button
								onClick={handleModalClose}
								variant="outlined"
								color="primary"
								fullWidth
								className={classes.drawerCancel}
							>
								{textValues.cancel}
							</Button>
						</Box>
					</Box>
				</Drawer>
			);
		}

		return (
			<Modal
				open={props.modal}
				onClose={(_, reason) => {
					if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
						handleModalClose();
					}
				}}
			>
				<Box className={classes.filterModal}>
					<JsonForm
						uiSchema={props.filterUiSchema}
						schema={props.filterSchema}
						data={props.filters}
						onChange={handleChange}
					/>
					<Box className={classes.modalActions}>
						<Button
							onClick={handleModalClose}
							variant="outlined"
							color="primary"
							className={classes.modalCancel}
						>
							{textValues.cancel}
						</Button>
						<Button
							onClick={props.applyFilter}
							variant="contained"
							color={'success'}
							className={classes.modalApply}
						>
							{textValues.applyFilter}
						</Button>
					</Box>
				</Box>
			</Modal>
		);
	};

	return (
		<Box className={classes.container}>
			<Box>
				<IconButton onClick={handleModalOpen} disableRipple>
					{props.filterApplied ? (
						<>
							<FilterAlt
								fontSize={isMobile ? 'medium' : 'large'}
								classes={{
									root: cx(classes.appliedFilterIcon, classes.cursorPointer),
								}}
							/>
							<CheckCircle classes={{ root: classes.appliedFilterSubIcon }} />
						</>
					) : (
						<FilterAlt
							fontSize={isMobile ? 'medium' : 'large'}
							classes={{ root: cx(classes.filterIcon, classes.cursorPointer) }}
						/>
					)}
				</IconButton>
			</Box>
			{renderModal()}
		</Box>
	);
}

FilterSort.propTypes = {
	filters: PropTypes.object,
	modal: PropTypes.bool,
	setFilters: PropTypes.func.isRequired,
	applyFilter: PropTypes.func.isRequired,
	filterApplied: PropTypes.bool.isRequired,
	filterSchema: PropTypes.object.isRequired,
	filterUiSchema: PropTypes.object.isRequired,
	handleModalClose: PropTypes.func.isRequired,
	handleModalOpen: PropTypes.func.isRequired,
};
FilterSort.defaultProps = {
	filters: {},
	modal: false,
};
