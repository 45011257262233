import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

//constants
import { scholarshipLabels } from './constant';

// mui
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Typography,
} from '@mui/material';

// utils
import { goToRoute } from '@/store/actions';
import useUserModal from '@/utils/hooks/use-login-modal';
import { getRedirectionLink } from './util';

//style
import useStyles from './style';

export default function ScholarshipCard({
	name,
	amount,
	link,
	universityId,
	trackEvent,
	...props
}) {
	const { classes } = useStyles();
	const profileData = useSelector((state) => state.profile.data);
	const openLoginModal = useUserModal();
	const dispatch = useDispatch();

	const onClickLogin = () => {
		const redirectionLink = getRedirectionLink(profileData, universityId);
		trackEvent('BUTTON_CLICKED', {
			eventCategory: 'NAVIGATION',
			eventLabel: 'Connect with expert',
			eventAction: 'CLICKED',
			section: 'SCHOLARSHIP_CARD',
			subSection: name,
		});
		openLoginModal('login', '', { redirectionLink });
	};

	const onKnowMore = () => {
		trackEvent('BUTTON_CLICKED', {
			eventCategory: 'NAVIGATION',
			eventLabel: 'Know More',
			eventAction: 'CLICKED',
			section: 'SCHOLARSHIP_CARD',
			landingPageUrl: link,
			subSection: name,
		});
		dispatch(goToRoute(link));
	};
	const onKnowMoreUniversityPage = () => {
		trackEvent('BUTTON_CLICKED', {
			eventCategory: 'NAVIGATION',
			eventLabel: 'Know More',
			eventAction: 'CLICKED',
			section: 'SCHOLARSHIP_CARD',
			landingPageUrl: link,
			subSection: name,
		});
		dispatch(goToRoute(link));
	};

	return (
		<Card className={classes.card}>
			<CardContent>
				<Box className={classes.contentBox}>
					<Box>
						<Typography
							className={classes.scholarshipName}
							color="text.dark"
							component={'h4'}
							gutterBottom
						>
							{name}
						</Typography>
						<Typography className={classes.country}>
							{props.universityLocation || '-'}
						</Typography>
					</Box>
				</Box>

				<Box className={classes.universityWrapper}>
					<Box>
						<Typography className={classes.label}>
							{scholarshipLabels.universityLabel}
						</Typography>
						<Typography className={classes.university}>
							{props.universityName || '-'}
						</Typography>
					</Box>
					<Box>
						<Typography className={classes.label}>
							{scholarshipLabels.educationLevel}
						</Typography>
						<Typography className={classes.degree}>
							{props.level || '-'}
						</Typography>
					</Box>
				</Box>
				<Box className={classes.currencyLabel}>
					<Typography className={classes.label}>
						{props.amountlabelDynamic
							? scholarshipLabels.amountlabel
							: scholarshipLabels.amountlabelDynamic}
					</Typography>
					<Typography className={classes.currency}>{amount || '-'}</Typography>
				</Box>

				<Typography variant="body2"></Typography>
			</CardContent>
			<CardActions className={classes.cardActions}>
				<Button
					variant="contained"
					fullWidth
					className={classes.connectButton}
					onClick={onClickLogin}
				>
					{scholarshipLabels.buttonLable1}
				</Button>
				<Button
					fullWidth
					onClick={universityId ? onKnowMoreUniversityPage : onKnowMore}
					className={classes.link}
				>
					{scholarshipLabels.buttonLable2}
				</Button>
			</CardActions>
		</Card>
	);
}

ScholarshipCard.propTypes = {
	name: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	level: PropTypes.string,
	universityLocation: PropTypes.string,
	universityId: PropTypes.string,
	universityName: PropTypes.string,
	amount: PropTypes.string,
	amountlabelDynamic: PropTypes.bool,
};
