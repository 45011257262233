import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { scroller } from 'react-scroll';

// constants
import { rowsPerPageOptions, textValues } from './constants';

// mui
import SchoolIcon from '@mui/icons-material/School';
import { Box, Button, Grid, TablePagination } from '@mui/material';

//components
import { LoadingCard } from '@/modules/scholarshipPage/loadingCard';
import ScholarshipCard from '@/modules/scholarshipPage/scholarshipCard';
import CapsuleClearFilter from './partials/capsuleClearFilter';
import FilterSort from './partials/filterSort';

// actions
import { scholarshipListAction } from '@/store/actions/pageActions';
import * as eventActions from '@/utils/analytics';

// utils
import {
	getFormSchema,
	getFormUiSchema,
	getInitialFilter,
	getInitialPerPage,
} from './utils';

//style
import { getUniversities } from '@/api';
import { getFilteredUniversities } from '@/modules/courseFinder/utils';
import useStyles from './style';

export default function ScholarshipList({ searchQueryValues, ...props }) {
	const { classes } = useStyles();

	const globalData = useSelector((state) => state.global.data);
	const isDesktop = useSelector((state) => state.common.isDesktop);
	const router = useRouter();
	const { query, push } = router;

	// data
	const pendingAPICalls = React.useRef(0);
	const [scrollToTop, setScrollToTop] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [cardList, setCardList] = React.useState([]);

	// form
	const [capsule, setCapsule] = React.useState([]);
	const [modal, setModal] = React.useState(false);
	const [filterApplied, setFilterApplied] = React.useState(false);
	const [filters, configFilters] = React.useState(() =>
		getInitialFilter(searchQueryValues)
	);
	const searchDropdownFilterEnums = (globalData.countries || []).filter(
		(country) => !country.toLowerCase().includes('other')
	);
	const formSchema = React.useMemo(
		() => getFormSchema(searchDropdownFilterEnums),
		[]
	);
	const formUiSchema = React.useMemo(
		() => getFormUiSchema(filters.countries),
		[filters.countries]
	);

	// pagination
	const [page, setPage] = React.useState(
		parseInt(get(searchQueryValues, 'page', 1))
	);
	const [rowsPerPage, setRowsPerPage] = React.useState(
		getInitialPerPage(searchQueryValues)
	);
	const [count, setCount] = React.useState(0);

	const trackEvent = (eventName, eventProperties) => {
		eventActions.trackEvent(eventName, {
			...props.analyticsProps,
			...eventProperties,
		});
	};

	// pagination
	const handleChangePage = (_, newPage) => {
		const pageValue = newPage + 1;
		setPage(pageValue);
		updateQueryParams(pageValue, rowsPerPage);
		setScrollToTop(true);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value));
		setPage(1);
		updateQueryParams(1, event.target.value);
		setScrollToTop(true);
	};

	const setFilters = (filtersData) => {
		configFilters(filtersData);
		setScrollToTop(false);
	};

	const handleClearQueryParams = () => {
		trackEvent('BUTTON_CLICKED', {
			eventCategory: 'USER_EVENT',
			eventLabel: 'Clear All Filters',
			eventAction: 'CLICKED',
		});

		setFilterApplied(false);
		setFilters({});
		setCapsule([]);
		setPage(1);

		const queryParams = { ...query };
		delete queryParams.level;
		delete queryParams.universities;
		delete queryParams.universitiesName;
		delete queryParams.countries;
		queryParams.page = 1;

		push({ query: queryParams }, undefined, { shallow: true });
	};

	const updateQueryParams = (newPage, newPerPage) => {
		router.push(
			{ query: { ...query, page: newPage, per_page: newPerPage } },
			undefined,
			{ shallow: true }
		);
	};

	const applyFilter = () => {
		getFiltersCapsule(filters);
		const isFilters =
			(filters && filters.countries && filters.countries.length) ||
			(filters && filters.universities && filters.universities.length) ||
			(filters && filters.level && filters.level.length);

		if (isFilters) {
			trackEvent('BUTTON_CLICKED', {
				eventCategory: 'USER_EVENT',
				eventLabel: 'Apply Filters',
				eventAction: 'CLICKED',
				filterValues: filters,
			});
		} else {
			trackEvent('BUTTON_CLICKED', {
				eventCategory: 'USER_EVENT',
				eventLabel: 'Apply Filters',
				eventAction: 'CLICKED',
			});
		}

		const currentQueryParams = new URLSearchParams(router.query);

		if (filters && filters.countries) {
			currentQueryParams.set('countries', filters.countries.join(','));
		} else {
			currentQueryParams.delete('countries');
		}

		if (filters && filters.level) {
			currentQueryParams.set('level', filters.level.join(','));
		} else {
			currentQueryParams.delete('level');
		}

		if (filters && filters.universities) {
			const universityIds = filters.universities
				.map((university) => university._id)
				.join(',');
			const universityName = filters.universities
				.map((university) => university.name)
				.join(',');
			currentQueryParams.set('universities', universityIds);
			currentQueryParams.set('universitiesName', universityName);
		} else {
			currentQueryParams.delete('universities');
		}

		setPage(1);
		setRowsPerPage(15);
		currentQueryParams.set('page', '1');
		currentQueryParams.set('per_page', '15');

		const updatedQueryString = currentQueryParams.toString();

		router.push(
			{ pathname: router.pathname, query: updatedQueryString },
			undefined,
			{ shallow: true }
		);

		const isFilterApplied =
			(filters && filters.countries && filters.countries.length) ||
			(filters && filters.universities && filters.universities.length) ||
			(filters && filters.level && filters.level.length);

		setFilterApplied(!!isFilterApplied);
		getFiltersCapsule(filters);
		setModal(false);
	};

	const applyFilterAfterDelete = (filters) => {
		const currentQueryParams = new URLSearchParams(router.query);

		if (filters && filters.countries) {
			currentQueryParams.set('countries', filters.countries.join(','));
		} else {
			currentQueryParams.delete('countries');
		}

		if (filters && filters.level) {
			currentQueryParams.set('level', filters.level.join(','));
		} else {
			currentQueryParams.delete('level');
		}

		if (filters && filters.universities) {
			const universityIds = filters.universities
				.map((university) => university._id)
				.join(',');
			const universityName = filters.universities
				.map((university) => university.name)
				.join(',');
			currentQueryParams.set('universities', universityIds);
			currentQueryParams.set('universitiesName', universityName);
		} else {
			currentQueryParams.delete('universities');
		}

		setPage(1);
		setRowsPerPage(15);
		currentQueryParams.set('page', '1');
		currentQueryParams.set('per_page', '15');

		const updatedQueryString = currentQueryParams.toString();

		router.push(
			{ pathname: router.pathname, query: updatedQueryString },
			undefined,
			{ shallow: true }
		);
	};

	function getFiltersCapsule(filters) {
		let combinedArray = [];
		if (filters && filters.countries) {
			combinedArray = combinedArray.concat(filters.countries);
		}
		if (filters && filters.level) {
			combinedArray = combinedArray.concat(filters.level);
		}
		if (filters && filters.universities) {
			const universityNames = filters.universities.map(
				(university) => university.name
			);
			combinedArray = combinedArray.concat(universityNames);
		}
		setCapsule(combinedArray);
	}

	const debouncedListAction = React.useCallback(
		debounce((filters, scrollToTop = false) => {
			setLoading(true);
			++pendingAPICalls.current;
			if (scrollToTop)
				scroller.scrollTo(textValues.scrollIdPaggination, { offset: -80 });
			scholarshipListAction(filters)
				.then((response) => {
					if (pendingAPICalls.current !== 1) return;
					setCardList([...response.data]);
					setCount(parseInt(response.meta.pagination.total));
				})
				.finally(() => {
					if (pendingAPICalls.current === 1) setLoading(false);
					setLoading(false);
					--pendingAPICalls.current;
				});
		}, 300),
		[]
	);

	function deleteSpecificFilter(elementToDelete) {
		let obj = getInitialFilter(searchQueryValues);
		for (const key in obj) {
			if (Array.isArray(obj[key])) {
				const index = obj[key].indexOf(elementToDelete);
				if (index !== -1) {
					obj[key].splice(index, 1);
					break;
				}

				if (key === 'universities' && elementToDelete) {
					const filteredUniversities = obj[key].filter(
						(university) => university.name !== elementToDelete
					);
					obj[key] = filteredUniversities;
					break;
				}
			}
		}
		if (capsule && capsule.length > 1) {
			applyFilterAfterDelete(obj);
			setFilters(obj);
		} else {
			handleClearQueryParams();
			applyFilterAfterDelete({});
		}
	}

	const handleClose = () => {
		setModal(!modal);
	};
	const handleModalOpen = () => setModal(true);

	const paginationClasses = {
		classes: {
			root: classes.paginationRoot,
			toolbar: classes.paginationToolbar,
			selectLabel: classes.paginationSelectLabel,
			displayedRows: classes.paginationDisplayedRows,
			actions: classes.paginationActions,
		},
		SelectProps: {
			className: classes.paginationSelectRoot,
		},
	};

	React.useEffect(() => {
		const isFilterApplied =
			(filters && filters.countries && filters.countries.length) ||
			(filters && filters.universities && filters.universities.length) ||
			(filters && filters.level && filters.level.length);

		if (isFilterApplied) {
			getFiltersCapsule(filters);
			setFilterApplied(true);
		}

		debouncedListAction(searchQueryValues, scrollToTop);
	}, [searchQueryValues]);

	React.useEffect(() => {
		if (filters.universities && filters.universities.length) {
			const filteredUniversities = getFilteredUniversities(filters);
			if (filteredUniversities.length !== filters.universities.length) {
				let newFilters = filters;
				newFilters.universities = filteredUniversities;
				setFilters(newFilters);
			}
		}
	}, [filters.countries]);

	React.useEffect(() => {
		if (!isEmpty(filters.universities)) {
			const apis = [];
			filters.universities.forEach((university) => {
				apis.push(
					getUniversities({ ids: university._id }).then(
						(result) => result.data[0]
					)
				);
			});

			Promise.all(apis)
				.then((result) => {
					setFilters({ ...filters, universities: result });
				})
				.catch(() => {});
		}
	}, []);

	return (
		<Box className={classes.containerBox}>
			<Box id={textValues.scrollIdPaggination}>
				<Box className={classes.filterContainer}>
					<Box>
						{capsule.length ? (
							<CapsuleClearFilter
								deleteSpecificFilter={deleteSpecificFilter}
								filter={capsule}
								handleModalOpen={handleModalOpen}
							/>
						) : null}
					</Box>

					<Box>
						<FilterSort
							filterUiSchema={formUiSchema}
							filterSchema={formSchema}
							filters={filters}
							filterApplied={filterApplied}
							setFilters={setFilters}
							modal={modal}
							handleModalClose={handleClose}
							handleModalOpen={handleModalOpen}
							applyFilter={applyFilter}
						/>
					</Box>
				</Box>

				{capsule.length > 0 && (
					<Box className={classes.clearFilter}>
						<Button onClick={handleClearQueryParams}>
							{textValues.clearAllFilters}
						</Button>
					</Box>
				)}
			</Box>

			<Box>
				<Grid container spacing={6}>
					{loading ? (
						Array.from({ length: 15 }, (_, index) => (
							<Grid item xs={12} sm={6} md={4} key={index}>
								<LoadingCard />
							</Grid>
						))
					) : cardList && cardList.length ? (
						cardList.map((data, key) => (
							<Grid item xs={12} sm={6} md={4} key={key}>
								<ScholarshipCard {...data} trackEvent={trackEvent} />
							</Grid>
						))
					) : (
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							className={classes.noScholarship}
						>
							<Grid xs={12} sm={12} md={12}>
								<SchoolIcon classes={{ root: classes.schoolIcon }} />
							</Grid>
							<Grid xs={12} sm={12} md={12}>
								{textValues.noData}
							</Grid>
						</Grid>
					)}
				</Grid>
			</Box>
			{cardList.length > 0 && (
				<TablePagination
					component="div"
					count={count}
					page={page - 1}
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={rowsPerPageOptions}
					labelRowsPerPage={'Per Page'}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					{...(isDesktop ? {} : paginationClasses)}
				/>
			)}
		</Box>
	);
}

ScholarshipList.propTypes = {
	analyticsProps: PropTypes.object,
	searchQueryValues: PropTypes.object,
};
ScholarshipList.defaultProps = {
	analyticsProps: {},
	searchQueryValues: {},
};
