import PropTypes from 'prop-types';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';
import { textValues } from './constants';

//components
import TitleSection from '@/components/shared/titleSection';
import ScholarshipCard from '@/modules/scholarshipPage/scholarshipCard';
import { Box, Button, Grid } from '@mui/material';

// utils
import { goToExternalRoute } from '@/store/actions';
import * as eventActions from '@/utils/analytics';

//style
import useStyles from './style';

export default function ScholarshipGroup({ cardList, ...props }) {
	const { classes } = useStyles();

	const trackEvent = (eventName, eventProperties) => {
		eventActions.trackEvent(eventName, {
			...props.analyticsProps,
			...eventProperties,
		});
	};

	const viewAllClick = () => {
		trackEvent('BUTTON_CLICKED', {
			eventCategory: 'NAVIGATION',
			eventLabel: textValues.allScholarshipTrigger,
			eventAction: 'CLICKED',
			landingPageUrl: '/scholarships',
		});

		goToExternalRoute(
			'',
			'/scholarships',
			null,
			{
				universities: props.universityId,
				universitiesName: props.universityName,
			},
			true
		);
	};

	return (
		<Box className={classes.containerBox}>
			<Box>
				<Box className={classes.titleWraper}>
					<TitleSection title={textValues.title} fontSize={titleSizes.medium} />
				</Box>
				<Grid container spacing={6}>
					{cardList.length > 0 &&
						cardList.map((data, key) => (
							<Grid item xs={12} sm={6} md={4} key={key}>
								<ScholarshipCard {...data} trackEvent={trackEvent} />
							</Grid>
						))}
				</Grid>
			</Box>
			{cardList.length > 0 && (
				<Box className={classes.buttonWrapper}>
					<Button onClick={viewAllClick} className={classes.buttonAncor}>
						{textValues.allScholarshipTrigger}
					</Button>
				</Box>
			)}
		</Box>
	);
}

ScholarshipGroup.propTypes = {
	universityId: PropTypes.string,
	universityName: PropTypes.string,
	cardList: PropTypes.array.isRequired,
	analyticsProps: PropTypes.object,
};

ScholarshipGroup.defaultProps = {
	cardList: [],
	analyticsProps: {},
};
