import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
	containerBox: { display: 'flex-box' },
	titleWraper: { marginBottom: '16px' },
	buttonWrapper: {
		display: 'flex',
		textAlign: 'right',
		justifyContent: 'flex-start',
	},
	buttonAncor: {
		fontWeight: '400',
		fontSize: '16px',
		color: '#F16621',
		marginTop: '8px',
	},
	noScholarship: {
		width: '100%',
		textAlign: 'center',
		marginTop: '30px !important',
		fontWeight: 500,
	},
	clearFilter: {
		display: 'flex',
		justifyContent: 'center',
		'& button': {
			fontSize: '16px',
			fontWeight: '500',
		},
	},
	filterContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'baseline',
	},
	paginationRoot: {
		overflow: 'hidden',
	},
	paginationToolbar: {
		flexWrap: 'wrap',
		justifyContent: 'center',
		padding: 0,
	},
	paginationSelectLabel: {
		textAlign: 'right',
		margin: '0px',
		fontSize: '14px',
	},
	paginationSelectRoot: {
		margin: '0px 8px',
	},
	paginationDisplayedRows: {
		margin: '0px',
		textAlign: 'right',
		fontSize: '14px',
	},
	paginationActions: {
		marginLeft: '0px!important',
	},
	schoolIcon: {
		color: '#C0C0C0',
		width: '64px',
		height: '64px',
	},
}));

export default useStyles;
