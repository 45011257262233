import { Box, Card, CardContent, Skeleton } from '@mui/material';

//style
import useStyles from './style';

export function LoadingCard() {
	const { classes } = useStyles();
	return (
		<Card className={classes.cardContainer}>
			<CardContent>
				<Skeleton
					animation="wave"
					height={40}
					width="80%"
					className={classes.skeleton}
				/>
				<Box className={classes.skeletonWrapper}>
					<Skeleton
						animation="wave"
						height={30}
						width="45%"
						className={classes.skeleton}
					/>
					<Skeleton
						animation="wave"
						height={30}
						width="45%"
						className={classes.skeleton}
					/>
				</Box>
				<Box className={classes.skeletonWrapper}>
					<Skeleton
						animation="wave"
						height={30}
						width="45%"
						className={classes.skeleton}
					/>
					<Skeleton
						animation="wave"
						height={30}
						width="45%"
						className={classes.skeleton}
					/>
				</Box>

				<Skeleton
					animation="wave"
					height={40}
					width="100%"
					className={classes.skeleton}
				/>
			</CardContent>
		</Card>
	);
}
