export const rowsPerPageOptions = [15, 30, 45];

export const textValues = {
	searchCourses: 'Search Courses',
	filters: 'Filters',
	applyFilter: 'Apply Filter',
	clearAllFilters: 'Clear All Filters',
	cancel: 'Cancel',
	scrollIdPaggination: 'top',
	noData: 'No Scholarship Found',
	allScholarshipTrigger: 'View All Scholarships',
	title: 'Scholarship',
};
