import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	filterLabel: { margin: '15px 0px', fontWeight: 500 },
	filterContainer: { display: 'flex' },
	capsuleContainer: {
		display: 'flex',
		justifyContent: 'left',
		flexWrap: 'wrap',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
		},
	},
	capsule: {
		padding: '3px 10px',
		border: '1px solid #FF9131',
		borderRadius: '18px',
		color: 'rgba(241, 105, 33, 0.99)',
		background: '#FFE3D6',
		margin: '10px',
		fontSize: '16px',
		fontWeight: '600',
	},
	mobileCapsule: {
		padding: '3px 10px',
		border: '1px solid #FF9131',
		borderRadius: '18px',
		color: 'rgba(241, 105, 33, 0.99)',
		background: '#FFE3D6',
		margin: '5px',
		fontSize: '14px',
		fontWeight: '600',
	},
	mobileIconButton: { padding: '0 0 3px 3px' },
	iconButton: {
		margin: '6px 0px 7px 8px',
		padding: '0px',
	},
	clearFilter: {
		display: 'flex',
		justifyContent: 'center',
		'& button': {
			fontSize: '16px',
			fontWeight: '500',
		},
	},
	mobileCapsuleContainer: { display: 'flex' },
	link: {
		display: 'inline-flex',
		WebkitBoxAlign: 'center',
		alignItems: 'center',
		WebkitBoxPack: 'center',
		justifyContent: 'center',
		position: 'relative',
		boxSizing: 'border-box',
		outline: '0',
		border: '0',
		margin: '0',
		cursor: 'pointer',
		userSelect: 'none',
		verticalAlign: 'middle',
		WebkitAppearance: 'none',
		textDecoration: 'none',
		fontWeight: 600,
		lineHeight: 1.75,
		minWidth: 'unset',
		padding: '6px 16px',
		textTransform: 'capitalize',
		borderRadius: '18px',
		fontSize: '14px',
		color: '#7D99FC',
		textAlign: 'center',
	},
	cancelIcon: {
		height: '17px',
		width: '17px',
		color: '#F16921',
		opacity: '0.987054',
	},
}));

export default useStyles;
