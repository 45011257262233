/* eslint-disable no-mixed-spaces-and-tabs */
import { levelAndType } from '@/utils/constants/program';
import get from 'lodash/get';

export const intakesEnumOptions = [
	{
		label: 'Jan to Apr',
		value: 'Jan-Feb-Mar-Apr',
	},
	{
		label: '\xa0 Jan',
		value: 'Jan',
	},
	{
		label: '\xa0 Feb',
		value: 'Feb',
	},
	{
		label: '\xa0 Mar',
		value: 'Mar',
	},
	{
		label: '\xa0 Apr',
		value: 'Apr',
	},
	{
		label: 'May to Jul',
		value: 'May-Jun-Jul',
	},
	{
		label: '\xa0 May',
		value: 'May',
	},
	{
		label: '\xa0 Jun',
		value: 'Jun',
	},
	{
		label: '\xa0 Jul',
		value: 'Jul',
	},
	{
		label: 'Aug to Dec',
		value: 'Aug-Sep-Oct-Nov-Dec',
	},
	{
		label: '\xa0 Aug',
		value: 'Aug',
	},
	{
		label: '\xa0 Sep',
		value: 'Sep',
	},
	{
		label: '\xa0 Oct',
		value: 'Oct',
	},
	{
		label: '\xa0 Nov',
		value: 'Nov',
	},
	{
		label: '\xa0 Dec',
		value: 'Dec',
	},
];

export function schema(options) {
	return {
		type: 'object',
		properties: {
			universities: {
				type: 'array',
				items: {
					type: 'object',
					properties: {
						_id: {
							type: 'string',
						},
						name: {
							type: 'string',
						},
					},
					required: ['_id', 'name'],
				},
			},
			countries: {
				type: 'array',
				items: {
					type: 'string',
					enum: options.countries || ['india'],
				},
				minItems: 1,
			},
			level_and_type: {
				type: 'string',
				enum: Object.values(levelAndType),
			},
			intakes: {
				type: 'array',
				items: {
					type: 'string',
					enum: intakesEnumOptions.map((item) => {
						return item.value;
					}),
				},
			},
			interest_regions: {
				type: 'array',
				items: {
					type: 'object',
					properties: {
						city: {
							type: 'string',
						},
						state: {
							type: 'string',
						},
						country: {
							type: 'string',
						},
					},
					required: ['country'],
				},
			},
			keywords: {
				type: 'array',
				items: {
					type: 'string',
				},
			},
		},
		required: ['keywords', 'level_and_type', 'countries'],
	};
}
export function getUischema({ countries }) {
	return {
		type: 'VerticalLayout',
		elements: [
			{
				type: 'HorizontalLayout',
				elements: [
					{
						type: 'Control',
						scope: '#/properties/countries',
						options: {
							component: 'multiSelect',
						},
					},
				],
			},
			{
				type: 'HorizontalLayout',
				elements: [
					{
						type: 'Control',
						scope: '#/properties/universities',
						options: {
							component: 'search',
							searchApi: 'universitySearch',
							searchApiArgs:
								countries && countries.length
									? [
											{
												q: '{{search}}',
												countries: countries,
											},
									  ]
									: [{ q: '{{search}}' }],
							indexApi: 'getUniversities',
							indexApiArgs: [{ ids: '{{ids}}' }],
							valueKey: '_id',
							labelKey: 'name',
						},
					},
				],
			},
			{
				type: 'HorizontalLayout',
				elements: [
					{
						type: 'Control',
						scope: '#/properties/interest_regions',
						label: 'Regions',
						options: {
							component: 'search',
							searchApi: 'regionSearch',
							searchApiArgs: [
								{
									cities: '{{search}}',
									countries,
								},
							],
							valueKey: 'label',
							labelKey: 'label',
						},
						rule: {
							effect: countries && countries.length ? 'ENABLE' : 'DISABLE',
							condition: {
								scope: '',
								schema: {},
							},
						},
					},
				],
			},
			{
				type: 'HorizontalLayout',
				elements: [
					{
						type: 'Control',
						scope: '#/properties/keywords',
						label: 'Program',
						options: {
							component: 'search',
							searchApi: 'keywordSearch',
							searchApiArgs: [{ text: '{{search}}' }],
							valueKey: 'keyword',
							labelKey: 'keyword',
						},
					},
				],
			},
			{
				type: 'HorizontalLayout',
				elements: [
					{
						type: 'Control',
						scope: '#/properties/intakes',
						options: {
							component: 'multiSelect',
							enumOptions: intakesEnumOptions,
						},
					},
				],
			},
			{
				type: 'HorizontalLayout',
				elements: [
					{
						type: 'Control',
						scope: '#/properties/level_and_type',
						label: 'Program Level and Type',
						options: {
							showClearIcon: true,
						},
					},
				],
			},
		],
	};
}

export function modalSchema(options) {
	return {
		type: 'object',
		properties: {
			countries: {
				type: 'array',
				items: {
					type: 'string',
					enum: options.countries || ['india'],
				},
				minItems: 1,
			},
			level_and_type: {
				type: 'string',
				enum: Object.values(levelAndType),
			},
			keywords: {
				type: 'array',
				items: {
					type: 'string',
				},
			},
			interest_regions: {
				type: 'array',
				items: {
					type: 'object',
					properties: {
						city: {
							type: 'string',
						},
						state: {
							type: 'string',
						},
						country: {
							type: 'string',
						},
					},
					required: ['country'],
				},
			},
		},
		required: ['keywords', 'level_and_type', 'countries', 'interest_regions'],
	};
}
export function getModalUischema({ countries }) {
	return {
		type: 'VerticalLayout',
		elements: [
			{
				type: 'HorizontalLayout',
				elements: [
					{
						type: 'Control',
						scope: '#/properties/countries',
						options: {
							component: 'multiSelect',
							questionOnMobile: true,
						},
						label: 'Which are your target countries?',
					},
				],
			},
			{
				type: 'HorizontalLayout',
				elements: [
					{
						type: 'Control',
						scope: '#/properties/level_and_type',
						label: 'What course are you looking to apply for?',
						options: {
							questionOnMobile: true,
						},
					},
				],
			},
			{
				type: 'HorizontalLayout',
				elements: [
					{
						type: 'Control',
						scope: '#/properties/keywords',
						label: 'Which stream does your target program fall under?',
						options: {
							component: 'search',
							searchApi: 'keywordSearch',
							searchApiArgs: [{ text: '{{search}}' }],
							valueKey: 'keyword',
							labelKey: 'keyword',
							questionOnMobile: true,
						},
					},
				],
			},
			{
				type: 'HorizontalLayout',
				elements: [
					{
						type: 'Control',
						scope: '#/properties/interest_regions',
						label: 'What regions do yo prefer?',
						options: {
							component: 'search',
							searchApi: 'regionSearch',
							searchApiArgs: [
								{
									cities: '{{search}}',
									countries,
								},
							],
							valueKey: 'label',
							labelKey: 'label',
						},
						rule: {
							effect: countries && countries.length ? 'ENABLE' : 'DISABLE',
							condition: {
								scope: '',
								schema: {},
							},
						},
					},
				],
			},
		],
	};
}

export function getIntakesFromQueryParams(intakes) {
	const intakesFromQuery = intakes.split(',');
	let intakesOptions = [];
	intakesFromQuery.forEach((intake) => {
		if (intake === 'Jan-Feb-Mar-Apr') {
			intakesOptions.push(...['Jan', 'Feb', 'Mar', 'Apr']);
		} else if (intake === 'May-Jun-Jul') {
			intakesOptions.push(...['May', 'Jun', 'Jul']);
		} else if (intake === 'Aug-Sep-Oct-Nov-Dec') {
			intakesOptions.push(...['Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
		} else {
			intakesOptions.push(intake);
		}
	});
	return String([...new Set(intakesOptions)]);
}

export function getFilteredUniversities(filters) {
	let allowedCountries = get(filters, 'countries', []);
	if (!allowedCountries.length) return filters.universities;
	let filteredUniversities = filters.universities.filter((i) => {
		if (i.location && i.location.country) {
			return allowedCountries.includes(i.location.country);
		}
		return true;
	});
	return filteredUniversities;
}

export function getFilteredRegions(filters) {
	const allowedCountries = get(filters, 'countries', []).map((country) =>
		country.toLowerCase()
	);

	return allowedCountries.length
		? filters.interest_regions.filter(({ country }) =>
				country ? allowedCountries.includes(country.toLowerCase()) : true
		  )
		: [];
}

export function getQueryParamsToSet(filters, searchText) {
	let query = [];
	if (searchText) {
		query.push({ key: 'q', value: searchText });
	}
	if (filters.universities && filters.universities.length) {
		query.push({
			key: 'university_ids',
			value: filters.universities.map((i) => i._id),
		});
	}
	if (filters.countries && filters.countries.length) {
		query.push({
			key: 'countries',
			value: filters.countries,
		});
	}
	if (filters.intakes && filters.intakes.length) {
		query.push({
			key: 'intakes',
			value: filters.intakes,
		});
	}
	if (filters.level_and_type) {
		query.push({ key: 'level_and_type', value: filters.level_and_type });
	}
	if (filters.keywords) {
		query.push({ key: 'keywords', value: filters.keywords });
	}
	if (filters.interest_regions && filters.interest_regions.length) {
		query.push({
			key: 'interest_regions',
			value: JSON.stringify(filters.interest_regions),
		});
	}
	return query;
}

export function getFiltersDataFromSearchParams(search) {
	let filtersData = {};
	if (search.get('level_and_type'))
		filtersData['level_and_type'] = search.get('level_and_type');
	if (search.get('university_ids'))
		filtersData.universities = search
			.get('university_ids')
			.split(',')
			.map((i) => ({ _id: i }));
	if (search.get('countries'))
		filtersData.countries = search.get('countries').split(',');
	if (search.get('intakes'))
		filtersData.intakes = search.get('intakes').split(',');
	if (search.get('keywords'))
		filtersData.keywords = search.get('keywords').split(',');
	if (search.get('interest_regions'))
		filtersData.interest_regions = JSON.parse(search.get('interest_regions'));
	return filtersData;
}

export function getUniversityProgramPagePath(universitySlug, programName) {
	const searchParams = new URLSearchParams();
	searchParams.set('q', programName);
	searchParams.set('page', 1);
	searchParams.set('per_page', 15);

	return `/course-finder/universities/${universitySlug}/program?${searchParams.toString()}`;
}
