import { get } from 'lodash';
import { rowsPerPageOptions } from './constants';

export const getInitialFilter = (searchQueryValues) => {
	const initialValue = {};

	if (get(searchQueryValues, 'countries'))
		initialValue.countries = decodeURIComponent(
			searchQueryValues.countries
		).split(',');

	if (get(searchQueryValues, 'level'))
		initialValue.level = searchQueryValues.level.split(',');

	if (get(searchQueryValues, 'universities')) {
		let universitiesArray = decodeURIComponent(
			searchQueryValues.universities
		).split(',');
		let universitiesName = decodeURIComponent(
			searchQueryValues.universitiesName
		).split(',');

		initialValue.universities = universitiesArray.map(
			(universityId, index) => ({
				_id: universityId.trim(),
				name: universitiesName[index].trim(),
			})
		);
	}
	return initialValue;
};

export const getInitialPerPage = (searchQueryValues) => {
	const perPage = parseInt(get(searchQueryValues, 'per_page', 0));
	return rowsPerPageOptions.includes(perPage) ? perPage : 15;
};

export const getFormUiSchema = (countries) => {
	return {
		type: 'VerticalLayout',
		elements: [
			{
				type: 'Control',
				scope: '#/properties/countries',
				label: 'Country',
				options: {
					component: 'multiSelect',
				},
			},
			{
				type: 'Control',
				scope: '#/properties/universities',
				options: {
					component: 'search',
					searchApi: 'universitySearch',
					searchApiArgs:
						countries && countries.length
							? [{ q: '{{search}}', countries: countries }]
							: [{ q: '{{search}}' }],
					indexApi: 'getUniversities',
					indexApiArgs: [{ ids: '{{ids}}' }],
					valueKey: '_id',
					labelKey: 'name',
				},
			},
			{
				type: 'Control',
				scope: '#/properties/level',
				label: 'Level of Education',
				options: {
					component: 'multiSelect',
				},
			},
		],
	};
};

export const getFormSchema = (searchDropdownFilterEnums) => {
	return {
		type: 'object',
		properties: {
			universities: {
				type: 'array',
				items: {
					type: 'object',
					properties: {
						_id: {
							type: 'string',
						},
						name: {
							type: 'string',
						},
					},
					required: ['_id', 'name'],
				},
			},
			level: {
				type: 'array',
				items: {
					type: 'string',
					enum: ['Bachelor', 'Master', 'PhD'],
				},
			},
			countries: {
				type: 'array',
				items: {
					type: 'string',
					enum: searchDropdownFilterEnums,
				},
			},
		},
		required: [],
	};
};
