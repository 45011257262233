import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	appliedFilterIcon: {
		color: '#78A5FF',
		position: 'absolute',
		right: '0px',
	},
	appliedFilterSubIcon: {
		position: 'absolute',
		right: '0px',
		fontSize: '14px',
		color: theme.palette.success.main,
		background: 'white',
		borderRadius: '50%',
		top: '-10px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '10px',
			top: '-5px',
		},
	},
	container: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginBottom: '20px',
	},
	filterIcon: {
		color: '#78A5FF',
	},
	drawer: {
		width: '100%',
	},
	drawerActions: { display: 'block' },
	drawerCancel: {
		color: '#232323',
		backgroundColor: 'transparent',
		border: '1px solid #232323',
		borderRadius: '33px',
		padding: '5px 30px',
	},
	drawerApply: {
		borderRadius: '33px',
		padding: '5px 30px',
		marginBottom: '10px',
	},
	modalActions: { display: 'flex', justifyContent: 'center' },
	modalCancel: {
		color: '#232323',
		backgroundColor: 'transparent',
		border: '1px solid #232323',
		borderRadius: '33px',
		padding: '5px 30px',
		margin: '10px',
	},
	modalApply: {
		borderRadius: '33px',
		padding: '5px 30px',
		margin: '10px',
	},
	filterModal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)!important',
		width: '80%',
		background: 'white !important',
		boxShadow: '24px',
		padding: '32px',
		borderRadius: '30px',
	},
}));

export default useStyles;
