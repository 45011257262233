import PropTypes from 'prop-types';

//components
import ScholarshipGroup from './group';
import ScholarshipList from './list';

export default function ScholarshipDataList(props) {
	const analyticsProps = {
		...props.analyticsProps,
		section: 'SCHOLARSHIPS_LIST',
	};

	if (props.cardList) {
		return (
			<ScholarshipGroup
				cardList={props.cardList}
				universityId={props.universityId}
				universityName={props.universityName}
				analyticsProps={analyticsProps}
			/>
		);
	}

	return (
		<ScholarshipList
			searchQueryValues={props.searchQueryValues}
			analyticsProps={analyticsProps}
		/>
	);
}

ScholarshipDataList.propTypes = {
	universityId: PropTypes.string,
	universityName: PropTypes.string,
	cardList: PropTypes.array,
	searchQueryValues: PropTypes.object,
	analyticsProps: PropTypes.object,
};

ScholarshipDataList.defaultProps = {
	analyticsProps: {},
};
