import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
	cardContainer: {
		minWidth: 275,
		border: '1px solid #ABABAB',
		boxShadow: 'unset',
		borderRadius: '10px',
	},
	skeletonWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	skeleton: {
		marginBottom: 4,
	},
}));

export default useStyles;
