import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
	card: {
		minWidth: 275,
		border: '1px solid #ABABAB',
		boxShadow: 'unset',
		borderRadius: '10px',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	link: {
		display: 'inline-flex',
		WebkitBoxAlign: 'center',
		alignItems: 'center',
		WebkitBoxPack: 'center',
		justifyContent: 'center',
		position: 'relative',
		boxSizing: 'border-box',
		outline: '0',
		border: '0',
		margin: '0',
		cursor: 'pointer',
		userSelect: 'none',
		verticalAlign: 'middle',
		WebkitAppearance: 'none',
		textDecoration: 'none',
		fontWeight: 600,
		lineHeight: 1.75,
		minWidth: '64px',
		padding: '6px 16px',
		width: '100%',
		textTransform: 'capitalize',
		borderRadius: '18px',
		fontSize: '14px',
		color: '#7D99FC',
		textAlign: 'center',
	},
	contentBox: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	scholarshipName: {
		fontSize: 18,
		fontWeight: 'bold',
		lineHeight: '24px',
		display: '-webkit-box',
		maxHeight: '48gipx',
		overflow: 'hidden',
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
		textOverflow: 'ellipsis',
	},
	country: { fontSize: '14px' },
	iconButton: {
		padding: '0px',
		alignItems: 'first baseline',
		height: '25px',
	},
	label: { fontSize: '12px', fontWeight: '500', color: '#E16E37' },
	university: { fontSize: '14px', marginRight: '10px', minHeight: '21px' },
	universityWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '20px',
	},
	nullUniversity: { textAlign: 'center' },
	cardActions: {
		display: 'flex',
		padding: '16px',
		height: '100%',
		flexDirection: 'column',
		justifyContent: 'end',
		'& > :not(:first-of-type)': {
			margin: '10px 0 0 0',
		},
	},
	currency: { fontSize: '14px', minHeight: '21px' },
	currencyLabel: { marginTop: '15px' },
	degree: { fontSize: '14px' },
	connectButton: { borderRadius: '18px', fontSize: '14px' },
}));

export default useStyles;
