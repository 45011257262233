import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

//materials
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, IconButton } from '@mui/material';

//styles
import useStyles from './style';

const truncateString = (inputString, maxLength) => {
	if (inputString.length > maxLength) {
		return inputString.slice(0, maxLength) + '...';
	} else {
		return inputString;
	}
};

const CapsuleClearFilter = ({
	deleteSpecificFilter,
	filter,
	handleModalOpen,
}) => {
	const isMobile = useSelector((state) => state.common.isMobile);
	const maxFiltersToShow = isMobile ? 2 : 5;
	const visibleFilters = filter.slice(0, maxFiltersToShow);
	const hiddenFilterCount = filter.length - maxFiltersToShow;
	const { classes } = useStyles();

	return (
		<>
			<Box className={classes.filterContainer}>
				<Box className={classes.filterLabel}>Filters:</Box>
				<Box className={classes.capsuleContainer}>
					{!isMobile ? (
						<>
							{visibleFilters.map((data, key) =>
								data ? (
									<Box className={classes.capsule} key={key}>
										{truncateString(data, 40)}
										<IconButton
											onClick={() => deleteSpecificFilter(data)}
											className={classes.iconButton}
										>
											<CancelIcon classes={{ root: classes.cancelIcon }} />
										</IconButton>
									</Box>
								) : null
							)}
							{hiddenFilterCount > 0 && (
								<Button onClick={handleModalOpen} className={classes.link}>
									+{hiddenFilterCount}
								</Button>
							)}
						</>
					) : null}
				</Box>
			</Box>
			{isMobile ? (
				<Box className={classes.mobileCapsuleContainer}>
					{visibleFilters.map((data, key) =>
						data ? (
							<Box className={classes.mobileCapsule} key={key}>
								{truncateString(data, 9)}
								<IconButton
									onClick={() => deleteSpecificFilter(data)}
									className={classes.mobileIconButton}
								>
									<CancelIcon classes={{ root: classes.cancelIcon }} />
								</IconButton>
							</Box>
						) : null
					)}
					{hiddenFilterCount > 0 && (
						<Button onClick={handleModalOpen} className={classes.link}>
							+{hiddenFilterCount}
						</Button>
					)}
				</Box>
			) : null}
		</>
	);
};

CapsuleClearFilter.propTypes = {
	filter: PropTypes.array,
	handleModalOpen: PropTypes.func.isRequired,
	deleteSpecificFilter: PropTypes.func.isRequired,
};

export default CapsuleClearFilter;
